<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tab_wrap">
        <div class="tab_list">
          <ul>
            <li
              :class="{ active: tabIndex == 0 }"
              @click.prevent="selectTabIndex(0)"
            >
              미입고현황
            </li>
            <li
              :class="{ active: tabIndex == 1 }"
              @click.prevent="selectTabIndex(1)"
            >
              입고현황
            </li>
            <li
              :class="{ active: tabIndex == 2 }"
              @click.prevent="selectTabIndex(2)"
            >
              식별표 입고
            </li>
          </ul>
        </div>
        <div class="tab_cont">
          <div class="tab01" v-if="tabIndex == 0 || tabIndex == 1">
            <div class="tab_opt" v-show="tabIndex == 1">
              <span class="option_txt">조회기간</span>
              <div>
                <div class="input_text">
                  <input
                    type="date"
                    placeholder="YYYY-MM-DD"
                    id="start_date"
                    :value="start_date"
                    @change="setStartDate($event)"
                    v-if="showSearchDate"
                  />
                </div>
                <p>~</p>
                <div class="input_text">
                  <input
                    type="date"
                    placeholder="YYYY-MM-DD"
                    id="end_date"
                    @change="setEndDate($event)"
                    :value="end_date"
                    v-if="showSearchDate"
                  />
                </div>
              </div>
            </div>
            <h6 class="views">
              조회수 :
              {{
                tabIndex == 0
                  ? filteredPurchaseMaterialList.length
                  : filteredPurchaseAccountList.length
              }}건
            </h6>
            <div class="mes_tbl_wrap">
              <table class="mes_tbl">
                <thead>
                  <tr>
                    <th>원자재명</th>
                    <th>구매처</th>
                    <th>수량(단위)</th>
                    <th>수입검사여부</th>
                    <th>단가</th>
                    <th>지출액</th>
                    <th>발주일자</th>
                    <th>{{ tabIndex == 0 ? `입고예정일` : `입고일` }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in tabIndex == 0
                      ? filteredPurchaseMaterialList
                      : filteredPurchaseAccountList"
                    @click="
                      tabIndex == 0 ? selectRow(index) : selectRowTabTwo(index)
                    "
                    :key="item.id"
                    :class="{
                      active: index == selectedIndex || index == selectedIndex2,
                    }"
                  >
                    <td>{{ getMaterialInfo(item.material_id).name }}</td>
                    <td>
                      {{ getCompanyInfo(item.company_id).name }}
                    </td>
                    <td>
                      {{
                        `${item.quantity}(${getUnitName(item.unit_id)})`
                          | makeComma
                      }}
                    </td>
                    <td>
                      {{
                        getMaterialInfo(item.material_id).inspection
                          ? '검사'
                          : '생략'
                      }}
                    </td>
                    <td>&#8361; {{ item.cost | makeComma }}</td>
                    <td>&#8361; {{ item.total_cost | makeComma }}</td>
                    <td>{{ item.input_date | formatDateNoHours }}</td>
                    <td>
                      {{
                        tabIndex == 0
                          ? item.incoming_date
                          : item.create_time | formatDateNoHours
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="tab03" v-else>
            <p>
              자재 입고식별표에 기입된 <span>바코드 번호</span>를 입력하거나<br />
              <span>바코드리더기</span>로 스캔하여주십시오.
            </p>
            <div class="input_text">
              <label>식별표</label>
              <input
                type="text"
                placeholder="식별표 번호를 입력하세요."
                @input="pushToBarcodeList($event)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="aside_management_mode">
      <div class="head">
        <h5>세부정보</h5>
      </div>
      <div class="tab01">
        <div class="form" @submit.prevent>
          <form>
            <div class="input_text">
              <label>입고일</label>
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="tabIndex == 0 ? input_date : managementData.create_time"
                @change="
                  $store.commit(
                    'setMaterialBaseInputDateToInput',
                    $event.target.value,
                  )
                "
                :readonly="selectedIndex == -1 || !isPermissionOn"
              />
            </div>
            <div class="input_text">
              <label>원자재명</label>
              <input
                type="text"
                placeholder=""
                id="rawMaterial"
                readonly
                :value="getMaterialInfo(managementData.material_id).name"
              />
            </div>
            <div class="input_text">
              <label>구매처명</label>
              <input
                type="text"
                placeholder=""
                id="purchase"
                readonly
                :value="getCompanyInfo(managementData.company_id).name"
              />
            </div>
            <div class="inputs">
              <div class="input_text">
                <label>수량</label>
                <input
                  type="text"
                  placeholder=""
                  id="quantity"
                  :value="$makeComma(managementData.quantity)"
                  inputmode="decimal"
                  @input="$inputNumber($event, managementData, 'quantity')"
                  :disabled="selectedIndex == -1 || !isPermissionOn"
                />
              </div>
              <div class="input_text">
                <label>단위</label>
                <input
                  type="text"
                  placeholder=""
                  id="ea"
                  readonly
                  :value="getUnitName(managementData.unit_id)"
                />
              </div>
            </div>
            <div class="input_text">
              <label class="require">입고창고</label>
              <my-selectric
                :id="`store_selectric`"
                :watch="managementData.store_id"
                :options="store_options"
                :state="
                  (selectedIndex == -1 && selectedIndex2 == -1) ||
                  !isPermissionOn
                    ? 'disabled'
                    : ''
                "
                :commit="'setMaterialManagementStoreIdToInput'"
                :key="reRend"
                @refresh="changeKey($event)"
              >
              </my-selectric>
            </div>
            <div class="input_text">
              <label>세부위치</label>
              <my-selectric
                v-if="managementData.store_id != null"
                :id="`location_selectric`"
                :watch="managementData.location_id"
                :options="managementData.location_options"
                :state="!isPermissionOn ? 'disabled' : ''"
                :commit="'setMaterialManagementLocationIdToInput'"
                :key="reRend2"
                @refresh="changeKey2($event)"
              >
              </my-selectric>
            </div>
            <div class="permission_input" v-if="inputPermission">
              <div class="input_text">
                <label>단가</label
                ><input
                  type="text"
                  placeholder=""
                  id="unitPrice"
                  :value="$makeComma(managementData.cost)"
                  inputmode="decimal"
                  @input="$inputNumberInt($event, managementData, 'cost')"
                  :disabled="
                    (selectedIndex == -1 && selectedIndex2 == -1) ||
                      !isPermissionOn
                  "
                />
              </div>
              <div class="input_text">
                <label>공급가액</label
                ><input
                  type="text"
                  placeholder=""
                  id="supplyValue"
                  readonly
                  v-show="isPermissionOn"
                  :value="getPrice(`supply`)"
                />
              </div>
              <div class="input_text">
                <label>부가세액</label
                ><input
                  type="text"
                  placeholder=""
                  id="taxAmount"
                  readonly
                  v-show="isPermissionOn"
                  :value="getPrice(`tax`)"
                />
              </div>
              <div class="input_text">
                <label>지출액</label
                ><input
                  type="text"
                  placeholder=""
                  id="taxTotalCost"
                  readonly
                  v-show="isPermissionOn"
                  :value="
                    managementData.total_cost != undefined
                      ? `₩ ${totalCost('supply', 'tax')}`
                      : ''
                  "
                />
              </div>
            </div>
            <div class="btn_wrap">
              <button
                v-if="
                  isPermissionOn &&
                    (selectedIndex != -1 || selectedIndex2 != -1)
                "
                type="button"
                class="btn_sub2"
                @click="
                  tabIndex == 0 || tabIndex == 2
                    ? submitForm()
                    : tabIndex == 1
                    ? changeStore()
                    : ''
                "
              >
                {{
                  `원자재 창고 ${
                    tabIndex == 0 || tabIndex == 2 ? `입고` : `수정`
                  }`
                }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <two-button-modal
      :modal_title="my_modal_title"
      :modal_content="my_modal_content"
      :isModalOpen="my_isModalOpen"
      :index="my_modal_index"
      @onclose="my_isModalOpen = false"
      @OnYesClick="
        () => {
          submitInputForm();
          my_isModalOpen = false;
        }
      "
    ></two-button-modal>
  </div>
</template>

<script>
import FetchMixin from '@/mixins/fetch';
import ModalMixin from '@/mixins/modal';
import SpinnerMixin from '@/mixins/spinner';
import PriceMixin from '@/mixins/material_price';
import MySelectric from '@/layouts/components/MySelectric.vue';
import FavoriteMixin from '@/mixins/favorite';
import { yyyymmdd } from '@/utils/func';
import { mapGetters } from 'vuex';
import TwoButtonModal from '@/layouts/components/TwoButtonModal';
export default {
  mixins: [FetchMixin, ModalMixin, SpinnerMixin, PriceMixin, FavoriteMixin],
  components: {
    MySelectric,
    TwoButtonModal,
  },
  data() {
    return {
      reRend: 1,
      reRend2: 1,
      tabIndex: 0,
      key: 0,
      showSearchDate: false,
      //delete modal
      my_modal_title: '입고 취소',
      my_modal_content: '입고 내역을 모두 취소하시겠습니까?',
      my_modal_index: -1,
      my_isModalOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      userId: 'getUserId',
      users: 'getUsersTempFromUserPage',
      order_purchases_type: 'getOrderPurchaseType',
      draft_purchases_type: 'getDraftPurchaseType',
      selectedIndex: 'getMaterialSelectedIndexFromInput',
      selectedIndex2: 'getPurchaseSelectedIndexFromInput',
      purchase_material_purchase: 'getPurchaseMaterialPurchaseOnlyNotInput',
      purchase_account: 'getPurchaseAccountFromInput',
      purchaseTypes: 'getPurchaseType',
      purchases: 'getPurchase',
      managementData: 'getMaterialManagementDataFromInput',
      companys: 'getCompany',
      materials: 'getMaterial',
      units: 'getUnitCodes',
      store_options: 'getStoresForSelectric',
      stores: 'getStores',
      input_date: 'getMaterialBaseInputDateFromInput',
      start_date: 'getStoreInputStartDateFromInput',
      end_date: 'getStoreInputEndDateFromInput',
    }),
    inputPermission() {
      const userRoleTypeId = this.users.find(x => x.account == this.userId)
        .user_role_type_id;
      return userRoleTypeId === 1 ? true : false;
    },
    filteredPurchaseMaterialList() {
      if (this.purchase_material_purchase.length > 0) {
        return this.lodash
          .clonedeep(this.purchase_material_purchase)
          .sort(
            (a, b) =>
              new Date(b.input_date) - new Date(a.input_date) || b.id - a.id,
          );
      } else {
        return [];
      }
    },
    filteredPurchaseAccountList() {
      if (this.purchase_account.length < 1 || this.purchases.length < 1) {
        return [];
      } else {
        const filterList = this.lodash
          .clonedeep(this.purchase_account)
          .filter(x =>
            this.purchaseTypes
              .filter(
                y => !['return purchase', 'draft purchase'].includes(y.detail),
              )
              .map(z => z.id)
              .includes(x.purchase.purchase_type_id),
          )
          .map(x =>
            x.purchase_material_list.map(y => ({
              ...y,
              send_id: y.material_in_out_list[0].material_control.id,
              company_id: x.purchase.company_id,
              input_date: this.purchases.find(z => z.id == x.purchase_id)
                .input_date,
              // create_time: this.purchases.find(z => z.id == x.purchase_id)
              //   .create_time,
              create_time: yyyymmdd(new Date(x.create_time)),
              supply: x.supply_value,
              vat_id: x.purchase.vat_id,
              store_id: y.material_in_out_list[0].material_control.store_id,
              location_id:
                y.material_in_out_list[0].material_control.location_id,
              location_options: this.getStoreLocationOptions(
                y.material_in_out_list[0].material_control.store_id,
              ),
            })),
          )
          .reduce((a, b) => a.concat(b))
          .sort((a, b) => new Date(b.input_date) - new Date(a.input_date));

        return filterList;
      }
    },
    checkTotalCostValid() {
      if (
        this.managementData != undefined &&
        this.managementData.vat_id != undefined &&
        this.managementData.tax_id != undefined
      ) {
        const calPrice = this.calSupplyTax(
          this.managementData.vat_id,
          this.managementData.tax_id,
          this.$makeNumber(this.managementData.quantity),
          this.$makeNumber(this.managementData.cost),
        ).total;
        if (calPrice <= 2147483647) {
          return true;
        } else {
          return false;
        }
      } else return false;
    },
    checkQuantityZero() {
      if (this.selectedIndex != -1) {
        let chkData = this.lodash.clonedeep(this.managementData).quantity;
        if (this.$makeNumber(chkData) == 0) {
          return false;
        }
        return true;
      } else return false;
    },
  },
  methods: {
    changeKey() {
      this.reRend += 1;
      const options = this.getStoreLocationOptions(
        this.managementData.store_id,
      );
      this.managementData.location_options = options;
      this.managementData.location_id = null;
    },
    changeKey2() {
      this.reRend2 += 1;
    },
    setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '입고일 오류',
            `입고일 조회기간 최후날짜(${this.end_date})<br>이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit('setStoreInputStartDateToInput', e.target.value);
          this.selectRow(-1);
          this.FETCH_WITH_PAYLOAD(
            'FETCH_PURCHASE_ACCOUNT_DAY_ALL',
            {
              start: this.start_date,
              end: this.end_date,
            },
            '구매 내역',
          );
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(
          'setStoreInputStartDateToInput',
          yyyymmdd(new Date()),
        );
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '입고일 오류',
            `입고일 조회기간 최초날짜(${this.start_date})<br>이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setStoreInputEndDateToInput', e.target.value);
          this.selectRow(-1);
          this.FETCH_WITH_PAYLOAD(
            'FETCH_PURCHASE_ACCOUNT_DAY_ALL',
            {
              start: this.start_date,
              end: this.end_date,
            },
            '구매 내역',
          );
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setStoreInputEndDateToInput', yyyymmdd(new Date()));
      }
    },
    selectTabIndex(index) {
      this.tabIndex = index;
      this.selectRow(-1);
      this.selectRowTabTwo(-1);
    },
    makeComma(value) {
      if (value == undefined) return '';
      if (value.length == 0) {
        return '0';
      }
      value = Number(String(value).replace(/,/gi, ''));
      if (value == 0) {
        return '0';
      }
      return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    getStoreLocationOptions(id) {
      if (id != null || id != undefined) {
        const storeInfo = this.stores.find(x => x.id == id);
        let options = [];
        options.push({ label: '선택', value: null });

        if (storeInfo != undefined) {
          const types = storeInfo.locations.sort((a, b) => a.id - b.id);
          types.forEach(el => {
            options.push({ label: el.name, value: el.id });
          });
        }
        return options;
      }
    },
    async submitForm() {
      if (
        this.managementData.store_id != null &&
        this.checkTotalCostValid &&
        this.checkQuantityZero
      ) {
        const payload = this.lodash.clonedeep(this.managementData);
        payload.input_yn = true;
        payload.completed = true;
        payload.cost = this.$makeNumber(payload.cost);
        payload.quantity = this.$makeNumber(payload.quantity);
        const recal_costs = this.lodash.clonedeep(
          this.calSupplyTax(
            payload.vat_id,
            payload.tax_id,
            payload.quantity,
            payload.cost,
          ),
        );
        payload.supply_value = recal_costs.supply;
        payload.tax = recal_costs.tax;
        payload.total_cost = recal_costs.total;
        payload.input_date = this.lodash.clonedeep(this.input_date);
        payload.purchase_materials = this.lodash.clonedeep(payload);
        this.showSpinner();
        this.$store
          .dispatch('UPDATE_ONE_ELEMENT_MATERIAL_WITH_PURCHASE', payload)
          .then(async () => {
            this.openOneButtonModal(
              '등록 성공',
              '자재를 성공적으로 입고처리하였습니다.',
            );
            await this.FETCH(
              'FETCH_PURCHASE_MATERIAL_PURCHASE',
              '미입고 발주항목',
            );
            this.selectRow(-1);
            this.selectRowTabTwo(-1);
          })
          .catch(() => {
            this.openOneButtonModal(
              '등록 실패',
              '등록 중 오류가 발생하였습니다.',
            );
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else if (this.managementData.store_id == null) {
        this.openOneButtonModal(
          '창고 선택',
          `입고창고는 반드시 선택하여 주십시오.`,
        );
        return;
      } else if (!this.checkTotalCostValid) {
        this.openOneButtonModal(
          '지출액 초과',
          '1회 입고 최대 지출액은<br />20억 이상 지출할 수 없습니다.',
        );
        return;
      } else if (!this.checkQuantityZero) {
        this.my_isModalOpen = false;
        this.openOneButtonModal(
          '입고 불가',
          '입고수량은 최소 1개 이상이어야 합니다.',
        );
        return;
      }
    },
    getMaterialInfo(id) {
      let hit = this.materials.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    getCompanyInfo(id) {
      let hit = this.companys.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    getUnitName(id) {
      let hit = this.units.find(x => x.id == id);
      return hit != undefined ? hit.name : '';
    },
    getPrice(price) {
      return this.managementData != undefined &&
        this.managementData.vat_id != undefined &&
        this.managementData.tax_id != undefined
        ? '₩ ' +
            this.makeComma(
              this.calSupplyTax(
                this.managementData.vat_id,
                this.managementData.tax_id,
                this.$makeNumber(this.managementData.quantity),
                this.$makeNumber(this.managementData.cost),
              )[price],
            )
        : '';
    },
    totalCost(supply, tax) {
      if (
        this.managementData != undefined &&
        this.managementData.vat_id != undefined &&
        this.managementData.tax_id != undefined
      ) {
        let total_cost = this.$decimalAdd(
          this.calSupplyTax(
            this.managementData.vat_id,
            this.managementData.tax_id,
            this.$makeNumber(this.managementData.quantity),
            this.$makeNumber(this.managementData.cost),
          )[supply],
          this.calSupplyTax(
            this.managementData.vat_id,
            this.managementData.tax_id,
            this.$makeNumber(this.managementData.quantity),
            this.$makeNumber(this.managementData.cost),
          )[tax],
        );
        return this.$makeComma(total_cost);
      }
    },
    async selectRow(index) {
      this.$store.commit('setMaterialSelectedIndexToInput', index);
      const date = new Date();
      this.$store.commit('setMaterialBaseInputDateToInput', yyyymmdd(date));
      if (index != -1) {
        await this.$store.commit(
          'setMaterialManagementDataToInput',
          this.filteredPurchaseMaterialList[index],
        );
        this.managementData.store_id = this.getMaterialInfo(
          this.managementData.material_id,
        ).default_store_id;
        this.managementData.location_id = null;
        if (this.managementData.store_id != null) {
          const options = this.getStoreLocationOptions(
            this.managementData.store_id,
          );
          this.managementData.location_options = options;
        }
      } else {
        this.$store.commit('setMaterialManagementDataToInput', []);
      }
    },
    async selectRowTabTwo(index) {
      this.$store.commit('setPurchaseSelectedIndexToInput', index);
      if (index != -1) {
        await this.$store.commit(
          'setMaterialManagementDataToInput',
          this.filteredPurchaseAccountList[index],
        );
        if (this.managementData.store_id != null) {
          const options = this.getStoreLocationOptions(
            this.managementData.store_id,
          );
          this.managementData.location_options = options;
        }
      } else {
        this.$store.commit('setMaterialManagementDataToInput', []);
      }
    },
    async changeStore() {
      this.showSpinner();
      const payload = this.lodash.clonedeep(this.managementData);
      payload.cost = this.$makeNumber(payload.cost);
      payload.quantity = this.$makeNumber(payload.quantity);
      const recal_costs = this.lodash.clonedeep(
        this.calSupplyTax(
          payload.vat_id,
          payload.tax_id,
          payload.quantity,
          payload.cost,
        ),
      );
      payload.supply_value = recal_costs.supply;
      payload.tax = recal_costs.tax;
      payload.total_cost = recal_costs.total;
      this.$store
        .dispatch('UPDATE_MATERIAL_CONTROL_STOCK', {
          id: payload.send_id,
          quantity: this.$makeNumber(payload.quantity),
          store_id: payload.store_id,
          location_id: payload.location_id,
          purchase_material_id:
            payload.material_in_out_list[0].purchase_material_id,
          cost: payload.cost,
          total_cost: payload.total_cost,
          supply_value: payload.supply_value,
          tax: payload.tax,
          purchase_account_id: payload.purchase_account_id,
        })
        .then(() => {
          this.managementData.quantity = this.$makeNumber(payload.quantity);
          this.openOneButtonModal(
            '재고 수정 완료',
            '재고 수정이 완료되었습니다.',
          );
          // this.FETCH('FETCH_MATERIAL_TOTAL_STOCK', '재고 현황');
          this.FETCH_WITH_PAYLOAD(
            'FETCH_PURCHASE_ACCOUNT_DAY_ALL',
            {
              start: this.start_date,
              end: this.end_date,
            },
            '구매 내역',
          );
          this.selectRowTabTwo(-1);
        })
        .catch(error => {
          console.log(error);
          this.openOneButtonModal('재고 수정 실패', '재고 수정 중 오류발생');
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    async FETCH_PURCHASE() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_PURCHASE', {
          start_date: '1950-01-01',
          end_date: '2500-12-30',
          check_only_not_completed: false,
        })
        .then(() => {})
        .catch(error => {
          console.log(error);
          this.openOneButtonModal(
            '로드 중 오류',
            '발주 리스트를 불러오는 중 오류발생',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },

    async pushToBarcodeList(e) {
      const barcode = e.target.value.trim();
      if (barcode.length == 13) {
        const purchase_material = this.filteredPurchaseMaterialList.find(
          x => x.id == Number(barcode.substr(-5)),
        );
        if (purchase_material == undefined) {
          this.openOneButtonModal(
            '등록 실패',
            '올바르지 않은 바코드거나 이미 입고처리된 식별표입니다.',
          );
        } else {
          const findIndex = this.filteredPurchaseMaterialList.findIndex(
            x => x.id == purchase_material.id,
          );
          this.$store.commit('setMaterialSelectedIndexToInput', findIndex);
          await this.$store.commit(
            'setMaterialManagementDataToInput',
            this.filteredPurchaseMaterialList[findIndex],
          );
        }
        e.target.value = '';
      }
    },
  },
  async created() {
    if (
      this.order_purchases_type == undefined ||
      this.order_purchases_type.length < 1
    ) {
      await this.FETCH('FETCH_PURCHASE_TYPE', '구매 유형');
    }
    if (this.input_date == null) {
      const date = new Date();
      this.$store.commit('setMaterialBaseInputDateToInput', yyyymmdd(date));
    }
    if (this.order_purchases_type.length < 1) {
      await this.FETCH('FETCH_PURCHASE_TYPE', '구매 유형');
    }
    if (this.materials.length < 1) {
      await this.FETCH('FETCH_MATERIAL_WITH_COMPANY', '원자재');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.stores.length == 0) {
      await this.FETCH('FETCH_STORE');
    }
    if (this.purchaseTypes.length == 0) {
      await this.FETCH('FETCH_MATERIAL_IN_OUT_TYPE', '자재 입출고 유형');
    }
    if (this.start_date == null) {
      var date = new Date();
      await this.$store.commit('setStoreInputEndDateToInput', yyyymmdd(date));
      var date2 = new Date(date.setDate(date.getDate() - 7));
      await this.$store.commit(
        'setStoreInputStartDateToInput',
        yyyymmdd(date2),
      );
    }
    await this.FETCH_WITH_PAYLOAD(
      'FETCH_PURCHASE_ACCOUNT_DAY_ALL',
      {
        start: this.start_date,
        end: this.end_date,
      },
      '구매 내역',
    );
    await this.FETCH_PURCHASE();
    await this.FETCH('FETCH_PURCHASE_MATERIAL_PURCHASE', '미입고 발주항목');
    this.showSearchDate = true;
  },
};
</script>

<style></style>
