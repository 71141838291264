<template>
  <div
    id="contents"
    class="storage_management"
    :class="{
      material_storage_input: tabIndex == 0,
      product_storage_input: tabIndex == 1,
    }"
  >
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <AsideSelectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToInputManagementPage'"
          :options="asideLists"
        />
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button type="button" class="btn_admin" @click="CloseThisPage()">
            닫기
          </button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li
            v-for="(asideList, index) in asideLists"
            :key="index"
            :class="{ active: tabIndex == index }"
          >
            <a href="#" @click.prevent="SetOpenTabIndex(index)">{{
              asideList.title
            }}</a>
          </li>
        </ul>
      </div>
      <MaterialStorageInputForm v-if="tabIndex == 0" />
      <ProductStorageInputForm v-if="tabIndex == 1" />
    </div>
  </div>
</template>

<script>
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import AsideSelectric from '@/layouts/components/AsideSelectric';
import { mapGetters, mapMutations } from 'vuex';
import MaterialStorageInputForm from '@/views/forms/Storage/MaterialStorageInputForm';
import ProductStorageInputForm from '@/views/forms/Storage/ProductStorageInputForm';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  data() {
    return {
      asideLists: [
        { title: '원자재 창고입고', name: 'material_storage_input' },
        { title: '제품 창고입고', name: 'product_storage_input' },
      ],
    };
  },
  components: {
    AsideSelectric,
    MaterialStorageInputForm,
    ProductStorageInputForm,
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromInputManagementPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToInputManagementPage',
    }),
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitInputManagementPage');
      this.$route.meta.menu_init = false;
    }
    if (this.$route.meta.tab != undefined) {
      this.$store.commit(
        'setOpenTabIndexToInputManagementPage',
        this.$route.meta.tab,
      );
      delete this.$route.meta.tab;
    }
  },
};
</script>

<style></style>
