<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tab_wrap">
        <div class="tab_list">
          <ul>
            <li
              :class="{ active: tabIndex == 0 }"
              @click.prevent="selectTabIndex(0)"
            >
              생산제품 입고
            </li>
            <li
              :class="{ active: tabIndex == 1 }"
              @click.prevent="selectTabIndex(1)"
            >
              반품 입고
            </li>
          </ul>
        </div>
        <div class="tab_cont">
          <div class="tab01" v-if="tabIndex == 0">
            <div class="tab_opt">
              <span class="option_txt">생산일자</span>
              <div>
                <div class="input_text">
                  <input
                    type="date"
                    placeholder="YYYY-MM-DD"
                    :value="control_start_date"
                    @change="setStartDate($event)"
                  />
                </div>
                <p>~</p>
                <div class="input_text">
                  <input
                    type="date"
                    placeholder="YYYY-MM-DD"
                    :value="control_end_date"
                    @change="setEndDate($event)"
                  />
                </div>
              </div>
              <div class="input_search">
                <input
                  type="text"
                  placeholder="제품명 또는 규격 검색"
                  :value="searchText"
                  @input="setSearchText($event)"
                />
                <button type="button"><i class="fa fa-search"></i></button>
              </div>
            </div>
            <h6 class="views">
              조회수 : {{ filteredProductControl.length }}건
            </h6>
            <div class="mes_tbl_wrap">
              <table class="mes_tbl">
                <thead>
                  <tr>
                    <th>생산일자</th>
                    <th>
                      제품명{{ systemCompany.expire_yn ? ` (유통기한)` : '' }}
                    </th>
                    <th>규격</th>
                    <th>입고수량(단위)</th>
                    <th>매출처</th>
                    <th>LOT 번호</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(product, index) in filteredProductControl"
                    :key="product.id"
                    @click="selectProductRow(index)"
                    :class="{ active: selectedProductIndex == index }"
                  >
                    <td>{{ product.create_time | formatDateNoHours }}</td>
                    <td class="text_left">
                      {{
                        getProductInfo(product.product_id).name +
                          (systemCompany.expire_yn
                            ? ` (${getExpireDate(product)}까지)`
                            : '')
                      }}
                    </td>
                    <td>
                      {{ getProductInfo(product.product_id).standard }}
                    </td>
                    <td class="text_right">
                      <b>{{ `${$makeComma(product.quantity)}` }}</b
                      >{{ `(${findInfoFromId(units, product.unit_id).name})` }}
                    </td>
                    <td>
                      {{
                        stockStatusList != undefined &&
                        stockStatusList != null &&
                        stockStatusList.length > 0
                          ? stockStatusList.find(
                              x => x.barcode_num == product.product_ident,
                            ).company_name
                          : ''
                      }}
                    </td>
                    <td>
                      {{ `L${product.product_ident}` }}
                      <button
                        class="btn_tbl"
                        type="button"
                        @click="trackingLotNum(product.product_ident)"
                      >
                        추적
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="tab02" v-else>
            <div class="tab_opt">
              <span class="option_txt">반품일자</span>
              <div>
                <div class="input_text">
                  <input
                    type="date"
                    placeholder="YYYY-MM-DD"
                    :value="retrun_start_date"
                    @change="setStartDate($event)"
                  />
                </div>
                <p>~</p>
                <div class="input_text">
                  <input
                    type="date"
                    placeholder="YYYY-MM-DD"
                    :value="retrun_end_date"
                    @change="setEndDate($event)"
                  />
                </div>
              </div>
              <div class="input_search">
                <input
                  type="text"
                  placeholder="제품명 검색"
                  @input="typing($event)"
                  :value="searchText2"
                />
                <button type="button"><i class="fa fa-search"></i></button>
              </div>
              <div class="input_search">
                <input
                  type="text"
                  placeholder="납품처 검색"
                  @input="typingCompany($event)"
                  :value="companySearchText"
                />
                <button type="button"><i class="fa fa-search"></i></button>
              </div>
            </div>
            <!-- 반품입고 -->
            <h6 class="views">조회수 : {{ return_list.length }}건</h6>
            <div class="mes_tbl_wrap">
              <table class="mes_tbl">
                <thead>
                  <tr>
                    <th>반품일</th>
                    <th>납품처</th>
                    <th>제품명</th>
                    <th>반품수량(단위)</th>
                    <th>입고창고</th>
                    <th>입고섹션</th>
                    <th>반품사유</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in return_list"
                    @click="selectRow(index)"
                    :class="{ active: selectedIndex == index }"
                    :key="item.id"
                  >
                    <td>{{ item.sales.create_time | formatDateNoHours }}</td>
                    <td class="text_left">
                      {{ findInfoFromId(companys, item.sales.company_id).name }}
                    </td>
                    <td class="text_left">
                      {{
                        findInfoFromId(products, item.sales_product.product_id)
                          .name
                      }}
                    </td>
                    <td class="text_right">
                      <b>{{ $makeComma(item.sales_product.quantity) }}</b
                      >{{
                        `(${
                          findInfoFromId(units, item.sales_product.unit_id).name
                        })`
                      }}
                    </td>
                    <td></td>
                    <td></td>
                    <td class="text_left">{{ item.sales.detail }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- -->
    <div class="aside_management_mode">
      <div class="head">
        <h5>세부정보</h5>
      </div>
      <div class="form" @submit.prevent>
        <form v-if="tabIndex == 0">
          <div class="input_text">
            <label>입고일</label>
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="
                managementProductData != null
                  ? managementProductData.create_time.substr(0, 10)
                  : ''
              "
              :disabled="managementProductData == null"
            />
          </div>
          <div class="input_text">
            <label>제품명</label>
            <input
              type="text"
              placeholder=""
              :value="
                managementProductData != null
                  ? getProductInfo(managementProductData.product_id).name
                  : ''
              "
              disabled
            />
          </div>
          <div class="input_text">
            <label>매출처</label>
            <input
              type="text"
              placeholder=""
              :value="
                managementProductData != null
                  ? stockStatusList != undefined &&
                    stockStatusList != null &&
                    stockStatusList.length > 0
                    ? stockStatusList.find(
                        x =>
                          x.barcode_num == managementProductData.product_ident,
                      ).company_name
                    : ''
                  : ''
              "
              disabled
            />
          </div>
          <div class="inputs">
            <div class="input_text">
              <label>입고수량</label>
              <input
                type="text"
                placeholder="수량"
                :value="
                  managementProductData != null
                    ? $makeComma(managementProductData.quantity)
                    : ''
                "
                @input="
                  $inputNumberInt($event, managementProductData, 'quantity')
                "
              />
            </div>
            <div class="input_text">
              <label>단위</label>
              <input
                type="text"
                placeholder=""
                id="ea"
                readonly
                :value="
                  managementProductData != null
                    ? findInfoFromId(units, managementProductData.unit_id).name
                    : ''
                "
              />
            </div>
          </div>
          <div class="input_text">
            <label class="require">입고창고</label>
            <my-local-selectric
              v-if="managementProductData != null"
              :id="`store_selectric`"
              :watch="managementProductData.store_id"
              :options="store_options"
              :state="selectedProductIndex == -1 ? 'disabled' : ''"
              @changeValue="
                changeSelectric($event, managementProductData, 'store_id')
              "
            >
            </my-local-selectric>
          </div>
          <div class="input_text">
            <label>세부위치</label>
            <my-local-selectric
              v-if="
                managementProductData != null &&
                  managementProductData.store_id != null
              "
              :id="`location_selectric`"
              :watch="managementProductData.location_id"
              :options="control_location_options"
              :state="selectedProductIndex == -1 ? 'disabled' : ''"
              @changeValue="
                changeSelectric($event, managementProductData, 'location_id')
              "
            >
            </my-local-selectric>
          </div>
          <div class="btn_wrap">
            <button
              type="button"
              class="btn_sub2"
              :disabled="selectedProductIndex == -1"
              @click="changeStore()"
            >
              제품 창고 입고
            </button>
          </div>
        </form>
        <form v-else class="form" @submit.prevent>
          <div class="input_text">
            <label>제품명</label>
            <input
              type="text"
              readonly
              placeholder="제품명"
              :value="
                managementData != null
                  ? findInfoFromId(
                      this.products,
                      managementData.sales_product.product_id,
                    ).name
                  : ''
              "
            />
          </div>
          <div class="input_text">
            <label>매출처</label>
            <input
              type="text"
              readonly
              placeholder="매출처"
              :value="
                managementData != null
                  ? findInfoFromId(
                      this.companys,
                      managementData.sales.company_id,
                    ).name
                  : ''
              "
            />
          </div>
          <div class="inputs">
            <div class="input_text">
              <label>입고수량</label>
              <input
                type="text"
                readonly
                placeholder="수량"
                :value="
                  managementData != null
                    ? $makeComma(managementData.sales_product.quantity)
                    : ''
                "
              />
            </div>
          </div>
          <!-- tabIndex == 1 : 반품입고 -->
          <div class="input_text">
            <label>입고창고</label>
            <my-local-selectric
              v-if="managementData != null"
              :id="'store_selectric_return'"
              :watch="managementData.product_control.store_id"
              :options="store_options"
              :state="selectedIndex == -1 ? 'disabled' : ''"
              @changeValue="
                changeSelectric(
                  $event,
                  managementData.product_control,
                  'store_id',
                )
              "
            >
            </my-local-selectric>
          </div>
          <div class="input_text">
            <label>세부위치</label>
            <my-local-selectric
              v-if="
                managementData != null &&
                  managementData.product_control.store_id != null
              "
              :id="'location_selectric_return'"
              :watch="managementData.product_control.location_id"
              :options="return_location_options"
              :state="selectedIndex == -1 ? 'disabled' : ''"
              @changeValue="
                changeSelectric(
                  $event,
                  managementData.product_control,
                  'location_id',
                )
              "
            >
            </my-local-selectric>
          </div>
          <div class="permission_input" v-if="tabIndex == 1 && inputPermission">
            <div class="input_text">
              <label>반환단가</label
              ><input
                type="text"
                readonly
                placeholder="반환단가"
                :value="getPrice('cost')"
              />
            </div>
            <div class="input_text">
              <label>공급가</label
              ><input
                type="text"
                readonly
                placeholder="공급가"
                :value="getPrice('supply_value')"
              />
            </div>
            <div class="input_text">
              <label>부가세</label
              ><input
                type="text"
                readonly
                placeholder="부가세"
                :value="getPrice('tax')"
              />
            </div>
            <div class="input_text">
              <label>반환액</label
              ><input
                type="text"
                readonly
                placeholder="반환액"
                :value="getPrice('total_cost')"
              />
            </div>
          </div>
          <div class="btn_wrap">
            <button
              type="button"
              class="btn_sub2"
              :disabled="selectedIndex == -1 || returnDataModify"
              @click="changeReturnProductStore()"
            >
              반품제품 창고 입고
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getExpireDate, yyyymmdd } from '@/utils/func';
import fetchMixin from '@/mixins/fetch';
import modalMixin from '@/mixins/modal';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric.vue';
import routes from '@/routes/routes';
export default {
  components: {
    MyLocalSelectric,
  },
  data() {
    return {
      tabIndex: 0,
      showModal: false,
      control_location_options: null,
      return_location_options: null,
    };
  },
  mixins: [fetchMixin, modalMixin],
  computed: {
    ...mapGetters({
      userId: 'getUserId',
      users: 'getUsersTempFromUserPage',
      products: 'getProduct',
      units: 'getUnitCodes',
      companys: 'getCompany',
      systemCompany: 'getSystemCompany',
      stores: 'getStores',
      store_options: 'getStoresForSelectric',
      productControlList: 'getProductControlDayList',
      stockStatusList: 'getProductStockStatusListFromProductStockStatus',
      return_listSrc: 'getReturnListFromProductReturnStatus',
      // tab 0 : 생산제품입고
      selectedProductIndex: 'getSelectedIndexFromProductControl',
      managementProductData: 'getManagementDataFromProductControl',
      control_start_date: 'getStartdateFromProductControl',
      control_end_date: 'getEnddateFromProductControl',
      searchText: 'getSearchTextFromProductControl',
      // tab 1 : 반품입고
      selectedIndex: 'getSelectedIndexFromProductReturnStatus',
      managementData: 'getManagementDataFromProductReturnStatus',
      retrun_start_date: 'getStartDateFromProductReturnStatus',
      retrun_end_date: 'getEndDateFromProductReturnStatus',
      searchText2: 'getSearchTextFromProductReturnStatus',
      companySearchText: 'getCompanySearchTextFromProductReturnStatus',
    }),
    // tab 0 : 생산제품입고
    filteredProductControl() {
      const Hangul = require('hangul-js');
      if (this.productControlList !== undefined) {
        return this.productControlList
          .filter(
            x =>
              this.getProductInfo(x.product_id).name.includes(
                this.searchText,
              ) ||
              Hangul.d(this.getProductInfo(x.product_id).name, true)
                .map(x => x[0])
                .join('')
                .includes(this.searchText) ||
              this.getProductInfo(x.product_id).standard.includes(
                this.searchText,
              ) ||
              Hangul.d(this.getProductInfo(x.product_id).standard, true)
                .map(x => x[0])
                .join('')
                .includes(this.searchText),
          )
          .sort((a, b) => new Date(b.create_time) - new Date(a.create_time));
      } else {
        return [];
      }
    },
    // tab 1 : 반품입고
    inputPermission() {
      const userRoleTypeId = this.users.find(x => x.account == this.userId)
        .user_role_type_id;
      return userRoleTypeId === 1 ? true : false;
    },
    return_list() {
      if (this.return_listSrc.length < 1 || this.products.length < 1) {
        return [];
      } else {
        const Hangul = require('hangul-js');
        return this.lodash
          .clonedeep(this.return_listSrc)
          .filter(
            x =>
              (this.findInfoFromId(
                this.products,
                x.sales_product.product_id,
              ).name.includes(this.searchText2) ||
                Hangul.d(
                  this.findInfoFromId(this.products, x.sales_product.product_id)
                    .name,
                  true,
                )
                  .map(x => x[0])
                  .join('')
                  .includes(this.searchText2)) &&
              (this.findInfoFromId(
                this.companys,
                x.sales.company_id,
              ).name.includes(this.companySearchText) ||
                Hangul.d(
                  this.findInfoFromId(this.companys, x.sales.company_id).name,
                  true,
                )
                  .map(x => x[0])
                  .join('')
                  .includes(this.companySearchText)),
          )
          .sort((a, b) => b.sales.id - a.sales.id);
      }
    },
    returnDataModify() {
      if (this.selectedIndex != -1) {
        const originData = JSON.stringify(
          this.lodash.clonedeep(this.return_list[this.selectedIndex])
            .product_control,
        );
        const manageData = JSON.stringify(
          this.lodash.clonedeep(this.managementData).product_control,
        );

        if (originData == manageData) {
          return true;
        } else return false;
      } else return true;
    },
  },
  methods: {
    selectTabIndex(index) {
      this.tabIndex = index;
      // this.selectRow(-1);
      // this.selectProductRow(-1);
    },
    async setStartDate(e) {
      const setTarget =
        this.tabIndex == 0
          ? 'setStartDateToProductControl'
          : 'setStartDateToProductReturnStatus';

      if (e.target.value != '') {
        const endDate =
          this.tabIndex == 0 ? this.control_end_date : this.retrun_end_date;
        if (e.target.value > endDate) {
          const startDate =
            this.tabIndex == 0
              ? this.control_start_date
              : this.retrun_start_date;

          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최후날짜(${endDate})<br>이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = startDate;
        } else {
          this.$store.commit(setTarget, e.target.value);
          if (this.tabIndex == 0) {
            this.FETCH_PRODUCT_CONTROL();
          } else {
            this.FETCH_PRODUCT_RETURN_LIST_DATE();
          }
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(setTarget, yyyymmdd(new Date()));
      }
    },
    setEndDate(e) {
      const setTarget =
        this.tabIndex == 0
          ? 'setEndDateToProductControl'
          : 'setEndDateToProductReturnStatus';

      if (e.target.value != '') {
        const startDate =
          this.tabIndex == 0 ? this.control_start_date : this.retrun_start_date;
        if (e.target.value < startDate) {
          const endDate =
            this.tabIndex == 0 ? this.control_end_date : this.retrun_end_date;

          this.openOneButtonModal(
            '조회기간 오류',
            `조회기간 최초날짜(${startDate})<br>이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = endDate;
        } else {
          this.$store.commit(setTarget, e.target.value);
          if (this.tabIndex == 0) {
            this.FETCH_PRODUCT_CONTROL();
          } else {
            this.FETCH_PRODUCT_RETURN_LIST_DATE();
          }
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit(setTarget, yyyymmdd(new Date()));
      }
    },
    changeSelectric(e, item, target) {
      if (item[target] != e) {
        item[target] = e;

        if (target == 'store_id') {
          if (this.tabIndex == 0) {
            this.control_location_options = this.getStoreLocationOptions(e);
          } else {
            this.return_location_options = this.getStoreLocationOptions(e);
          }
          item.location_id = null;
        }
      }
    },
    getStoreLocationOptions(id) {
      const storeInfo = this.stores.find(x => x.id == id);
      let options = [];
      options.push({ label: '선택', value: null });

      if (storeInfo != undefined) {
        const types = storeInfo.locations.sort((a, b) => a.id - b.id);
        types.forEach(el => {
          options.push({ label: el.name, value: el.id });
        });
      }
      return options;
    },
    // tab 0 : 반품입고
    setSearchText(e) {
      this.$store.commit('setSearchTextToProductControl', e.target.value);
    },
    selectProductRow(index) {
      this.$store.commit('setSelectedIndexToProductControl', index);
      if (index != -1) {
        this.$store.commit(
          'setManagementDataToProductControl',
          this.lodash.clonedeep(this.filteredProductControl[index]),
        );
        this.control_location_options = this.getStoreLocationOptions(
          this.managementProductData.store_id,
        );
      } else {
        this.$store.commit('setManagementDataToProductControl', null);
      }
    },
    getExpireDate(item) {
      const products = this.findInfoFromId(this.products, item.product_id);
      return yyyymmdd(
        new Date(
          getExpireDate(
            new Date(item.create_time),
            products.expire_year,
            products.expire_month,
            products.expire_day,
          ),
        ),
      );
    },
    getProductInfo(id) {
      let hit = this.products.find(x => x.id == id);
      return hit != undefined ? hit : '';
    },
    trackingLotNum(lotNum) {
      const goingPage = routes[0].children.find(
        x => x.path == '/product/tracking',
      );
      if (goingPage != undefined) {
        goingPage.meta.select = Number(lotNum.substr(8, 5));
      }
      this.$router.push('/product/tracking');
    },
    getPrice(price) {
      return this.managementData != null
        ? this.$makeComma(this.managementData.sales_product[price])
        : '';
    },
    async changeStore() {
      if (this.managementProductData.store_id != null) {
        this.showSpinner();
        const payload = this.lodash.clonedeep(this.managementProductData);
        this.$store
          .dispatch('UPDATE_PRODUCT_CONTROL_STOCK', payload)
          .then(() => {
            // this.managementProductData.quantity = this.$makeNumber(
            //   payload.quantity,
            // );
            this.openOneButtonModal('입고 완료', '창고 입고가 완료되었습니다.');
            this.FETCH_PRODUCT_CONTROL();
            this.selectProductRow(-1);
          })
          .catch(error => {
            console.log(error);
            this.openOneButtonModal('입고 실패', '제품 창고입고 중 오류발생');
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else {
        this.openOneButtonModal(
          '창고 선택',
          `입고창고는 반드시 선택하여 주십시오.`,
        );
        return;
      }
    },
    async FETCH_PRODUCT_CONTROL() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_PRODUCT_CONTROL_DAY_LIST', {
          start_date: this.control_start_date,
          end_date: this.control_end_date,
        })
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '로드 중 오류',
            '재고정보를 불러오는 중 오류가 발생했습니다.',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },

    // tab 1 : 반품입고
    selectRow(index) {
      this.$store.commit('setSelectedIndexToProductReturnStatus', index);
      if (index != -1) {
        this.$store.commit(
          'setManagementDataToProductReturnStatus',
          this.lodash.clonedeep(this.return_list[index]),
        );
        this.return_location_options = this.getStoreLocationOptions(
          this.managementData.product_control.store_id,
        );
      } else {
        this.$store.commit('setManagementDataToProductReturnStatus', null);
      }
    },
    typing(e) {
      this.$store.commit('setSearchTextToProductReturnStatus', e.target.value);
      this.$store.commit('setManagementDataToProductReturnStatus', null);
      this.$store.commit('setSelectedIndexToProductReturnStatus', -1);
    },
    typingCompany(e) {
      this.$store.commit(
        'setCompanySearchTextToProductReturnStatus',
        e.target.value,
      );
      this.$store.commit('setManagementDataToProductReturnStatus', null);
      this.$store.commit('setSelectedIndexToProductReturnStatus', -1);
    },
    async changeReturnProductStore() {
      if (this.managementData.product_control.store_id != null) {
        this.showSpinner();
        const payload = this.lodash.clonedeep(this.managementData);
        this.$store
          .dispatch('UPDATE_RETURN_PRODUCT_STORE', payload)
          .then(() => {
            this.openOneButtonModal('입고 완료', '창고 입고가 완료되었습니다.');
            this.FETCH_PRODUCT_RETURN_LIST_DATE();
            this.selectRow(-1);
          })
          .catch(error => {
            console.log(error);
            this.openOneButtonModal('입고 실패', '제품 창고입고 중 오류발생');
          })
          .finally(() => {
            this.hideSpinner();
          });
      } else {
        this.openOneButtonModal(
          '창고 선택',
          `입고창고는 반드시 선택하여 주십시오.`,
        );
        return;
      }
    },
    async FETCH_PRODUCT_RETURN_LIST_DATE() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_PRODUCT_RETURN_LIST_DATE', {
          start: this.retrun_start_date,
          end: this.retrun_end_date,
        })
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '로드 중 오류',
            '반품 기록 정보를 불러오는 중 오류가 발생했습니다.',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
  },
  async created() {
    if (this.retrun_start_date == null) {
      let date3 = new Date();
      this.$store.commit('setEndDateToProductControl', yyyymmdd(date3));
      this.$store.commit('setEndDateToProductReturnStatus', yyyymmdd(date3));
      let date4 = new Date(date3.setDate(date3.getDate() - 7));
      this.$store.commit('setStartDateToProductControl', yyyymmdd(date4));
      this.$store.commit('setStartDateToProductReturnStatus', yyyymmdd(date4));
    }

    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '거래처');
    }
    await this.FETCH('FETCH_PRODUCT_STATUS', '제품 현황');
    await this.FETCH_PRODUCT_CONTROL();
    await this.FETCH_PRODUCT_RETURN_LIST_DATE();

    if (this.$route.meta.target != undefined) {
      this.tabIndex = 1;
      if (this.return_list != undefined) {
        this.selectRow(
          this.return_list.findIndex(
            x => x.sales.id == this.$route.meta.target,
          ),
        );
      }
      delete this.$route.meta.target;
    }
  },
};
</script>

<style></style>
